import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

const TWO_HOURS = 2 * 60 * 60 * 1000

const state = {
    loaded: false,
    expiryDate: new Date,
    properties: [],
}

// getters
const getters = {
    checkExpiryDate: (state) => {
        return ((new Date) - state.expiryDate) > TWO_HOURS;
    },
    loaded: (state) => {
        return state.loaded;
    },
    properties: (state) => {
        return state.properties
    }
}

// actions
const actions = {
    getProperties (context){
        context.commit('getProperties')
    },
}

// mutations
const mutations = {
    getProperties (state){
        axios.get('https://strapi.uptowncreative.io/hannahs')
        .then((response) => {
            state.properties = response.data;
            state.properties.sort(function (first, second) {
                const a = first.PropertyData;
                const b = second.PropertyData;
                if(a.Special === b.Special){
                    return second.PropertyData.Price - first.PropertyData.Price;
                }else if(
                    (a.Special === 'newListing' || a.Special === 'offMarket' || a.Special === 'none')
                    &&
                    (b.Special === 'newListing' || b.Special === 'offMarket' || b.Special === 'none')){
                    return second.PropertyData.Price - first.PropertyData.Price;
                }else if(a.Special === 'newListing' || a.Special === 'offMarket' || a.Special === 'none'){
                    return -1;
                } else if(b.Special === 'newListing' || b.Special === 'offMarket' || b.Special === 'none'){
                    return 1;
                } else if(a.Special === 'inEscrow'){
                    return -1;
                } else if(b.Special === 'inEscrow'){
                    return 1;
                } else if(a.Special === 'sold' || a.Special === 'lease'){
                    return -1;
                } else if(b.Special === 'sold' || b.Special === 'lease'){
                    return 1;
                } else {
                    return second.PropertyData.Price - first.PropertyData.Price;
                }
            })
            state.loaded = true;
            state.expiryDate = new Date;
        })
    },
}

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations
})