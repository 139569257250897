<template>
    <div v-if="slides.length > 0" id="homepageSlideshow">
        <div v-if="loading">
            <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
        </div>
        <div class="content" v-else>
            <div class="info" 
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="1000">
                <transition name="fade">
                    <div class="content" key="1" v-show="animated">
                        <span class="tag">
                            {{ slides[index].PropertyData.Special}}
                        </span>
                        <h2>
                            {{slides[index].PropertyData.Address}}
                        </h2>
                        <span class="price">
                            {{slides[index].PropertyData.Price | priceFormat}}
                        </span>
                        <router-link class="details" :to="'/property/'+slides[index].PropertyData.Slug">
                            DETAILS
                        </router-link>
                    </div>
                </transition >
            </div>
            <vueper-slides
                :bullets="window.width > 576 ? false : true"
                :touchable="true"
                :pauseOnHover="false"
                class="no-shadow featured_slideshow"
                :fixed-height="adaptiveHeight"
                ref="homepageSlides"
                @slide="changeProperty"
                :dragging-distance="70"
                >
                <vueper-slide
                    v-for="(slide, i) in slides"
                    :key="i"
                    class="homepageSlide"
                    :image="url+ slide.PropertyData.DisplayImage.url"
                    >
                </vueper-slide>
            </vueper-slides>
        </div>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import Spinner from 'vue-simple-spinner'

export default {
    name: "HomepageSlideshow",
    components: { VueperSlides, VueperSlide, Spinner },
    data() {
        return {
            animated: true,
            index: 0,
            window: {
                width: 0,
                height: 0
            },
            mytimout:{},
            url: 'https://strapi.uptowncreative.io'
        }
    },
    computed:{
        adaptiveHeight: function(){
            const divWidth = this.window.width > 576 ? 0.65 : 1;
            return Math.min(1334,(this.window.width * 0.667))*divWidth.toFixed(2) + 'px';
        },
        slides: function(){
            return this.$store.getters.properties.filter((property) => {
                return property.PropertyData.Featured;
            })
        },
        loading: function(){
            return !this.$store.getters.loaded;
        }
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        changeProperty(event){
            this.animated = false
            clearTimeout(this.mytimout);
            this.mytimout = setTimeout(()=>{
                this.index = event.currentSlide.index;
                this.animated = true;
            }, 500)
        },
    },
    filters: {
        priceFormat: function (value) {
            if (!value) return ''
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0
            })
            
            return formatter.format(value)
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
}
</script>

<style lang="scss" scoped>
.content{
    display: flex;
    .info{
        width: 35%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .content{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            padding: 0 75px 0 8%;
            .tag{
                font-size: 1rem;
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 3px;
                background: $primaryColor;
                color: #fff;
                padding: 0.25rem 1rem;
                display: block;;
                z-index: 2;
            }
            h2{
                letter-spacing: 1px;
                font-size: 2rem;
                font-weight: 500;
                text-align: right;
                display: block;
            }
            .price{
                color: #4c4c4c;
                font-size: 1.05rem;
                letter-spacing: 3px;
                display: block;
            }
            .details{
                display: block;
                margin-top: 1.5rem;
                text-decoration: none;
                letter-spacing: 2px;
                font-weight: 600;
                color: $primaryColor;
                &:hover{
                    opacity: 0.75;
                }
            }
        }
    }
}
@media (max-width: 800px) {
    .content{
        .info{
            padding-left: 1rem;
            width: 45%;
        }
    }
}

@media (max-width: 576px) {
    .content{
        display: block;
        .info{
            width: 100%;
            margin-top: 2rem;
            padding: 2rem 0;
            .content{
                padding: 0;
                justify-content: center;
                align-items: center;
                h2{
                    text-align: center;
                }
            }
        }
    }
}
</style>
<style lang="scss">

.content .featured_slideshow{
    flex: 1;
    .vueperslides__bullet--active{
        color: #000;
    }
    .vueperslides__bullet .default{
        border-color: #000;
    }
}

.vueperslides__bullets{
    bottom: -4rem;
}

@media (max-width: 576px) {
    .featured_slideshow{
        margin-bottom: 6rem;
    }
}
</style>